import React from "react"
import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Topbar, MenuButtonLineDark, MenuButtonDark } from "./header-styles"
import { css } from "@emotion/core"

const HeaderDark = () => {
  const openMenu = () => {
    document.querySelector(".menu").classList.add("active")
    document.querySelector(".header__button").classList.add("open")
    document.querySelector(".menu video").play()
  }

  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "nl-icon.png" }) {
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Topbar>
      <Link to="/">
        <Img fixed={data.file.childImageSharp.fixed} />
      </Link>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .number {
            margin-right: 2rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            color: #1e1e1e;
            text-decoration: none;
          }
        `}
      >
        <a className="number" href="+tel:8665321187">
          866.532.1187
        </a>
        <MenuButtonDark className="header__button" onClick={openMenu}>
          <MenuButtonLineDark />
          <MenuButtonLineDark />
        </MenuButtonDark>
      </div>
    </Topbar>
  )
}

export default HeaderDark
