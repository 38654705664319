import React, { Component } from "react"
import SEO from "../components/seo"
import HeaderDark from "../components/header/header-dark"
import {
  ServiceWrapper,
  ServiceWrapperItem,
  SmokeButton,
} from "../styled-components/services-styles"
import Video from "../components/video/video"
import Smoke from "../videos/smoke-1.mp4"
import Smoke2 from "../videos/smoke-2.mp4"
import Smoke3 from "../videos/smoke-3.mp4"
import Smoke4 from "../videos/smoke-4.mp4"

class ServicesPage extends Component {
  playVideo(event) {
    const video = event.target.querySelector("video")
    if (video) {
      video.play()
    }
  }

  pauseVideo(event) {
    const video = event.target.querySelector("video")
    if (video) {
      video.pause()
    }
  }

  playAllVideo() {
    const videos = document.querySelectorAll("video")
    if (videos) {
      videos.forEach(video => {
        video.play()
      })
    }
  }

  pauseAllVideo() {
    const videos = document.querySelectorAll("video")
    if (videos) {
      videos.forEach(video => {
        video.pause()
      })
    }
  }

  render() {
    return (
      <>
        <SEO title="Services" />
        <HeaderDark />
        <ServiceWrapper className="services">
          <SmokeButton
            to="/all-services/"
            onMouseEnter={this.playAllVideo}
            onMouseLeave={this.pauseAllVideo}
          >
            View all services
          </SmokeButton>
          <ServiceWrapperItem
            to="/websites-for-lawyers/"
            onMouseEnter={this.playVideo}
            onMouseLeave={this.pauseVideo}
          >
            <Video vidSrc={Smoke} autoPlay={false} loop={true} />
            <svg width="600" height="338" viewBox="0 0 600 338" id="nBlock">
              <defs>
                <mask id="nMask" x="0" y="0" width="600" height="338">
                  <rect x="0" y="0" width="600" height="338" fill="#fefefe" />
                  <path d="M455.5,9.7v317.4h-88L246.9,182.9v144.2H142.7V9.7h88l120.6,144.2V9.7H455.5z" />
                </mask>
              </defs>
              <rect x="0" y="0" width="600" height="338" />
            </svg>
            <span>NextLevel Design</span>
          </ServiceWrapperItem>
          <ServiceWrapperItem
            to="/seo-for-lawyers/"
            onMouseEnter={this.playVideo}
            onMouseLeave={this.pauseVideo}
          >
            <Video vidSrc={Smoke2} autoPlay={false} loop={true} />
            <svg width="600" height="338" viewBox="0 0 600 338" id="eBlock">
              <defs>
                <mask id="eMask" x="0" y="0" width="600" height="338">
                  <rect x="0" y="0" width="600" height="338" fill="#fefefe" />
                  <path d="M435.8,246.4v80.7H171.9V9.7h258v80.7H277.1v37.2h134.2v77.1H277.1v41.7H435.8z" />
                </mask>
              </defs>
              <rect x="0" y="0" width="600" height="338" />
            </svg>
            <span>NextLevel SEO</span>
          </ServiceWrapperItem>
          <ServiceWrapperItem
            to="/website-content-for-lawyers/"
            onMouseEnter={this.playVideo}
            onMouseLeave={this.pauseVideo}
          >
            <Video vidSrc={Smoke3} autoPlay={false} loop={true} />
            <svg width="600" height="338" viewBox="0 0 600 338" id="xBlock">
              <defs>
                <mask id="xMask" x="0" y="0" width="600" height="338">
                  <rect x="0" y="0" width="600" height="338" fill="#fefefe" />
                  <path
                    d="M353,327.6l-54.9-84.8l-53.5,84.8h-122l114.7-160.1L126.7,10.2h120.2l54,79.8l52.6-79.8h115.2
    L358.4,163.5l117,164.1H353z"
                  />
                </mask>
              </defs>
              <rect x="0" y="0" width="600" height="338" />
            </svg>
            <span>NextLevel Content</span>
          </ServiceWrapperItem>
          <ServiceWrapperItem
            to="/video-for-lawyers/"
            onMouseEnter={this.playVideo}
            onMouseLeave={this.pauseVideo}
          >
            <Video vidSrc={Smoke4} autoPlay={false} loop={true} />
            <svg width="600" height="338" viewBox="0 0 600 338" id="tBlock">
              <defs>
                <mask id="tMask" x="0" y="0" width="600" height="338">
                  <rect x="0" y="0" width="600" height="338" fill="#fefefe" />
                  <path d="M246,93.2h-92.9v-83H446v83H353v234.4H246V93.2z" />
                </mask>
              </defs>
              <rect x="0" y="0" width="600" height="338" />
            </svg>
            <span>NextLevel Video</span>
          </ServiceWrapperItem>
        </ServiceWrapper>
      </>
    )
  }
}

export default ServicesPage
