import React from "react"

function Video(props) {
  return (
    <video
      autoPlay={props.autoPlay}
      loop={props.loop}
      infinite="true"
      muted={props.muted}
      controls={props.controls}
      poster={props.poster}
    >
      <source src={props.vidSrc} type="video/mp4" />
    </video>
  )
}

export default Video
