import styled from "@emotion/styled"
import { Link } from "gatsby"

export const ServiceWrapperItem = styled(Link)``

export const ServiceWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: #fefefe;
  display: grid;
  z-index: 49;
  transition: all 0.4s ease-in-out;
  position: relative;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 50vh 50vh;
  }

  ${ServiceWrapperItem} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    :nth-of-type(2) {
      background-color: #fefefe;
    }
    :nth-of-type(3) {
      background-color: #efefef;
    }
    :nth-of-type(4) {
      background-color: #efefef;
    }
    :nth-of-type(5) {
      background-color: #dedede;
    }

    @media screen and (max-width: 1023px) {
      min-height: 550px;
    }

    :hover {
      span {
        @media (min-width: 1024px) {
          left: 70%;
        }
      }
    }

    img,
    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      height: 338px;
      object-fit: cover;
    }

    svg {
      position: static;
      display: block;
      transform: none;
      z-index: 300;
      opacity: 1;
      transition: none;
      transform-origin: center;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.01);
      margin: 0;
      z-index: 2;
    }

    span {
      position: absolute;
      left: 45%;
      z-index: 3;
      display: inline-block;
      bottom: 25%;
      font-size: 20px;
      color: #2497c6;
      text-transform: lowercase;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      line-height: 1;
      transition: all 0.4s ease-in-out;

      @media (min-width: 1024px) {
        left: 60%;
      }
    }

    #nBlock > rect {
      mask: url(#nMask);
      fill: #fefefe;
    }
    #eBlock > rect {
      mask: url(#eMask);
      fill: #efefef;
    }
    #xBlock > rect {
      mask: url(#xMask);
      fill: #efefef;
    }
    #tBlock > rect {
      mask: url(#tMask);
      fill: #dedede;
    }
  }
`

export const SmokeButton = styled(Link)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #1d5f83;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: #fefefe;
  z-index: 50;
  padding: 1.5rem 3rem;
  text-decoration: none;
  letter-spacing: 3px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 1023px) {
    display: none;
  }

  :hover {
    background-color: #143c52;
    color: #fefefe;
  }
`
